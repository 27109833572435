export const CONFIG = {
  LinkedInApi: {
    clientId: '78mc87oyo019kw',
    redirectUri: 'https%3A%2F%2Fhubble-staging.decisionpoint.in%2Flinkedin-update',
    oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
    scope: 'r_liteprofile%20r_emailaddress',
    state: '123456',
  },
  CONVERSE_USING_TEAMS:"https://teams.microsoft.com/l/chat/0/0?users=28:f5115e1e-fc1c-43e4-ad84-d346367a4329",
  QoQ_GROWTH_CONFIG_1: [{
    chart: {
      height: 100,
      width: 200,
      type: 'column'
    },
    title: {
      text: 'QoQ Growth',
      align: 'left',
      x: 0,
      verticalAlign: 'top',
      y: 0,
      margin: 0,
      style: {
        fontSize: '12px',
        fontFamily: 'Montserrat',
      }
    },
    xAxis: {
      type: 'category',
      labels: {
        style: {
          fontSize: "10px",
          fontFamily: 'Montserrat',
          color: 'rgba(96, 96, 96, 0.9)'
        }
      }
    },
    yAxis: {
      visible: false,
      maxPadding: 0.2,
      title: {
        enabled: false
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        pointWidth: 10,
        borderRadius: 2
      }
    },
    series: [{
      color: "rgb(166 166 166 / 70%)",
      data: [
        ['Q1', 10],
        ['Q2', 18],
        ['Q3', 12],
        ['Q4', 16],
      ],
      dataLabels: {
        enabled: true,
        align: 'center',
        formatter: function () {
          if (this.point.y > 0) {
            return `+${this.point.y}%`
          } else return `-${this.point.y}%`
        },
        // x: 3,
        y: -5,
        style: {
          marginBottom: 20,
          textOutline: 0,
          fontSize: '10px',
          fontWeight: 700,
          fontFamily: 'Montserrat',
        }
      }
    }]
  }],
  QoQ_GROWTH_CONFIG_2: [{
    chart: {
      height: 100,
      width: 200,
      type: 'column'
    },
    title: {
      text: 'QoQ Growth',
      align: 'left',
      x: 0,
      verticalAlign: 'top',
      y: 0,
      margin: 0,
      style: {
        fontSize: '12px',
        fontFamily: 'Montserrat',
      }
    },
    xAxis: {
      type: 'category',
      labels: {
        style: {
          fontSize: "10px",
          fontFamily: 'Montserrat',
          color: 'rgba(96, 96, 96, 0.9)'
        }
      }
    },
    yAxis: {
      visible: false,
      maxPadding: 0.2,
      title: {
        enabled: false
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        pointWidth: 10,
        borderRadius: 2
      }
    },
    series: [{
      color: "rgb(166 166 166 / 70%)",
      data: [
        ['Q1', 10],
        ['Q2', 7],
        ['Q3', 5],
        ['Q4', 18],
      ],
      dataLabels: {
        enabled: true,
        align: 'center',
        formatter: function () {
          if (this.point.y > 0) {
            return `+${this.point.y}%`
          } else return `-${this.point.y}%`
        },
        // x: 3,
        y: -5,
        style: {
          marginBottom: 20,
          textOutline: 0,
          fontSize: '10px',
          fontWeight: 700,
          fontFamily: 'Montserrat',
        }
      }
    }]
  }],
  QoQ_GROWTH_CONFIG_3: [{
    chart: {
      height: 100,
      width: 200,
      type: 'column'
    },
    title: {
      text: 'QoQ Growth',
      align: 'left',
      x: 0,
      verticalAlign: 'top',
      y: 0,
      margin: 0,
      style: {
        fontSize: '12px',
        fontFamily: 'Montserrat',
      }
    },
    xAxis: {
      type: 'category',
      offset: -10,
      labels: {
        style: {
          fontSize: "10px",
          fontFamily: 'Montserrat',
          color: 'rgba(96, 96, 96, 0.9)'
        }
      }
    },
    yAxis: {
      visible: false,
      maxPadding: 0.2,
      title: {
        enabled: false
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        pointWidth: 10,
        borderRadius: 2
      }
    },
    series: [{
      color: 'rgb(166 166 166 / 70%)',
      data: [
        ['Q1', +10],
        ['Q2', -5],
        ['Q3', -3],
        ['Q4', -4],
      ],
      dataLabels: {
        enabled: true,
        align: 'center',
        formatter: function () {
          if (this.point.y > 0) {
            return `+${this.point.y}%`
          } else return `${this.point.y}%`
        },
        // x: 3,
        y: -5,
        style: {
          marginBottom: 20,
          textOutline: 0,
          fontSize: '10px',
          fontWeight: 700,
          fontFamily: 'Montserrat',
        }
      }
    }]
  }],
  CHART_TITLE_STYLE: {
    color: '#000000DE',
    fontWeight: 600,
    fontSize: '21px',
    fontFamily: 'Montserrat'
  },
  SEIRES_COLOR: ['#00A5A5', '#E92222', '#FFCD37', '#323883', '#005DAD', '#EF8600', '#FFCD8C', '#9F5900', '#909090', '#00717D', '#00A98F', '#81C46F', '#0086AF',],
  DATALABEL_STYLE: {
    textOutline: false,
    color: 'black',
    fontSize: '16px',
    fontFamily: 'Montserrat'
  },
  PLOTLINECONFIG: [
    {
      color: '#606060',
      width: 2,
      value: 0,
      zIndex: 5,
    },
  ],
  PROMOTION_DATA: [
    {
      "name": "Promotion ROI",
      "percentage": "10.3%",
      "comparision_percentage": "2.3%",
      "comparision_value": "vs PM"
    },
    {
      "name": "Store RGM",
      "percentage": "56.6%",
      "comparision_percentage": "2%",
      "comparision_value": "vs PM"
    },
    {
      "name": "PPK Shift",
      "percentage": "3.5%",
      "comparision_percentage": "1.5%",
      "comparision_value": "vs PM"
    },
    {
      "name": "Profit",
      "percentage": "32%",
      "comparision_percentage": "2.1%",
      "comparision_value": "vs PM"
    }
  ],
  CARD_SLIDE_DATA: [{
    "header": "Demand Planning",
    "data": [{
      "point": "Portfolio Innovation Scenario",
      "link": "#"
    },
    {
      "point": "Covid Lockdown Scenario",
      "link": "#"
    }]
  },
  {
    "header": "What If Promo",
    "data": [{
      "point": "Share Gain Scenario",
      "link": "#"
    },
    {
      "point": "High Volume Lift Scenario",
      "link": "#"
    }]
  },
  {
    "header": "Post Event Analyzer",
    "data": [{
      "point": "New Category Mix Scenario",
      "link": "#"
    },
    {
      "point": "Volume Growth Scenario",
      "link": "#"
    }]
  }],
  CARD_SLIDE_COLORS: ['rgba(255, 238, 176, 0.2)', 'rgba(187, 255, 214, 0.2)', 'rgba(255, 187, 240, 0.2)'],
  TOOL_ICON_BACKGROUND: ["#54CAA7", "#69D2D2", "#904FD2", "#904FD2", "#69D2D2", "#54CAA7", "#5E7AC3", "#904FD2", "#69D2D2", "#904FD2", "#54CAA7", "#69D2D2", "#5D5A9F", "#69D2D2", "#904FD2", "#904FD2", "#54CAA7", "#69D2D2", "#54CAA7"],
  TOOL_DATA: [
    {
      "section": "Recently Viewed Tools",
      "segemts": [
        {
          "segment": "Integrated",
          "apps": [
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Growth Decomposition Tool",
              "description": "Understand Growth Levers ",
              "projectLink": "market-share-tool"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Beagle",
              "description": "Get Frequent Alerts For Your Analysis",
              "projectLink": "simulator-chatbot"
            }
          ]
        },
        {
          "segment": "Promotion",
          "apps": [
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Promo Diagnostics Tool",
              "description": "Evaluate Promo Strategies and ROI",
              "projectLink": "post-event-promo-diagnosis-dashboards"
            }
          ]
        },
        {
          "segment": "Pricing",
          "apps": [
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Pricing Analytics & Purchase Structure",
              "description": "Get Actionable insights on Brand Tiering and stratification",
              "projectLink": "pricing-dashboard"
            }
          ]
        }
      ]
    },
    {
      "section": "All Tools",
      "segemts": [
        {
          "segment": "All Tools",
          "apps": [
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Promo Diagnostics Tool",
              "description": "Evaluate Promo Strategies and ROI",
              "projectLink": "post-event-promo-diagnosis-dashboards"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Promo What-If Simulator",
              "description": "Create Different Weekly Promo Scenarios",
              "projectLink": "promo-what-if"
            },
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Pricing Analytics & Purchase Structure",
              "description": "Get Actionable insights on Brand Tiering and stratification",
              "projectLink": "pricing-dashboard"
            },
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Price-Volume-Profit Simulator",
              "description": "Price Volume Profit Change simulator",
              "projectLink": "pvp"
            },
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Store RGM",
              "description": "Keep Track Of Store Execution Score",
              "projectLink": "ammps"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Assortment And Mix",
              "description": "Priotize Right Set of SKUs For Any Store",
              "projectLink": process.env.REACT_APP_MIX_MANAGEMENT,
              "direct_link":true
            },
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Availability On-Shelf",
              "description": "Daily Track Of Your SKUs Availability",
              "projectLink": "aos"
            },
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Asset Planogram Tool",
              "description": "Utilize Shelf Space To Maximize Returns",
              "projectLink": "asset-planogram-tool"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Activation Analytics",
              "description": "Analyse Platform Execution Performance",
              "projectLink": "activation-analytics"
            },
            {
              "logoLink": require("../Images/MicrosoftTeams-image (4).png"),
              "name": "Predictive Order",
              "description": "AI-powered tool that generates HYPER PERSONALIZED 'Suggested Orders'",
              "projectLink": "predictive-order"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "FABLE",
              "description": "Identify the Store level Opportunity",
              "projectLink": "fable"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Asset Placement Simulator",
              "description": "Identify the ideal placement of the asset",
              "projectLink": "Asset-placement-simulator"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Integrated Business Planner",
              "description": "Synchronized Planning With All Business Unit Involved",
              "projectLink": "integrated-commercial-planner"
            },
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Target Group Builder",
              "description": "Target Right Brands In Right Stores",
              "projectLink": "target-group-builder"
            },
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Demand Planning",
              "description": "Forecast Demands Accurately According To External Factors",
              "projectLink": "demand-planning"
            },
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Shopper Marketing",
              "description": "Know Your Audience For Your Portfolio",
              "projectLink": "pos-initiative"
            },
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Growth Decomposition Tool",
              "description": "Understand Growth Levers ",
              "projectLink": "market-share-tool"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Beagle",
              "description": "Get Frequent Alerts For Your Analysis",
              "projectLink": "simulator-chatbot"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Promotional Optimizer",
              "description": "Create Promo Calendar with optimal budget allocation",
              "projectLink": "promotion-optimizer"
            },
            {
              "logoLink": require("../Images/admin_icon.png"),
              "name": "Command Center Tool",
              "description": "Command Center Tool",
              "projectLink": "https://command-center.decisionpoint.in/",
              "direct_link":true
            },
          ]
        },
        {
          "segment": "Promotional Analytics",
          "apps": [
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Promo Diagnostics Tool",
              "description": "Evaluate Promo Strategies and ROI",
              "projectLink": "post-event-promo-diagnosis-dashboards"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Promo What-If Simulator",
              "description": "Create Different Weekly Promo Scenarios",
              "projectLink": "promo-what-if"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Promotional Optimizer",
              "description": "Create Promo Calendar with optimal budget allocation",
              "projectLink": "promotion-optimizer"
            }
          ]
        },
        {
          "segment": "Price Management",
          "apps": [
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Pricing Analytics & Purchase Structure",
              "description": "Get Actionable insights on Brand Tiering and stratification",
              "projectLink": "pricing-dashboard"
            },
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Price-Volume-Profit Simulator",
              "description": "Price Volume Profit Change simulator",
              "projectLink": "pvp"
            }
          ]
        },
        {
          "segment": "Trade Management",
          "apps": [
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "FABLE",
              "description": "Identify the Store level Opportunity",
              "projectLink": "fable"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Asset Placement Simulator",
              "description": "Identify the ideal placement of the asset",
              "projectLink": "Asset-placement-simulator"
            }
          ]
        },
        {
          "segment": "Assortment Analytics",
          "apps": [
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Store RGM",
              "description": "Keep Track Of Store Execution Score",
              "projectLink": "ammps"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Assortment And Mix",
              "description": "Priotize Right Set of SKUs For Any Store",
              "projectLink": process.env.REACT_APP_MIX_MANAGEMENT,
              "direct_link":true
            },
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Availability On-Shelf",
              "description": "Daily Track Of Your SKUs Availability",
              "projectLink": "aos"
            },
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Asset Planogram Tool",
              "description": "Utilize Shelf Space To Maximize Returns",
              "projectLink": "asset-planogram-tool"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Activation Analytics",
              "description": "Analyse Platform Execution Performance",
              "projectLink": "activation-analytics"
            },
            {
              "logoLink": require("../Images/MicrosoftTeams-image (4).png"),
              "name": "Predictive Order",
              "description": "AI-powered tool that generates HYPER PERSONALIZED 'Suggested Orders'",
              "projectLink": "predictive-order"
            },
          ]
        },
        {
          "segment": "Integrated Tools",
          "apps": [
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Integrated Business Planner",
              "description": "Synchronized Planning With All Business Unit Involved",
              "projectLink": "integrated-commercial-planner"
            },
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Target Group Builder",
              "description": "Target Right Brands In Right Stores",
              "projectLink": "target-group-builder"
            },
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Demand Planning",
              "description": "Forecast Demands Accurately According To External Factors",
              "projectLink": "demand-planning"
            },
            {
              "logoLink": require("../Images/Rocket.png"),
              "name": "Shopper Marketing",
              "description": "Know Your Audience For Your Portfolio",
              "projectLink": "pos-initiative"
            },
            {
              "logoLink": require("../Images/Geometry.png"),
              "name": "Growth Decomposition Tool",
              "description": "Understand Growth Levers ",
              "projectLink": "market-share-tool"
            },
            {
              "logoLink": require("../Images/Group 210.png"),
              "name": "Beagle",
              "description": "Get Frequent Alerts For Your Analysis",
              "projectLink": "simulator-chatbot"
            }
          ]
        }
        ,{
          "segment": "Admin",
          "apps": [
            {
              "logoLink": require("../Images/admin_icon.png"),
              "name": "Command Center Tool",
              "description": "Command Center Tool",
              "projectLink":process.env.REACT_APP_COMMAND_CENTER,
              "direct_link":true
            },
          ]
        },
      ]
    }
  ],

  TOOL_HOME_PAGE_DATA: [
    {
      "segment": "Promotional Analytics",
      "tool_name": "Promo Diagnostics Tool",
      "value_prop": "Track the performance of all your promotion investments in terms of profit ROI, Volume Uplift, Revenue Uplift",
      "value_desc": "",
      "key_points": [
        "Know your True Volume Uplift and Profit ROI",
        "Investment Reallocation at SKU level",
        "Portfolio cannibalization and Customer Forward Buying trends"
      ],
      "demo_link": "Yyqwor-e2s0",
      "tool_link":process.env.REACT_APP_PROMO_DIAGNOSTIC_TOOL,
      "embedded_tool_link":process.env.REACT_APP_PROMO_DIAGNOSTIC_TOOL_EMBEDDED=='null'?null:process.env.REACT_APP_PROMO_DIAGNOSTIC_TOOL_EMBEDDED,
      // "tool_link": "https://app.powerbi.com/view?r=eyJrIjoiZWFmOTBkOWEtNmY2NS00YTA2LWJhOTItZjIwZjgxNmIxMGI2IiwidCI6Ijc3MTg0ZDk5LTYwNjUtNDJiOS05NDUyLThiMmI3MDM4NTE5MyJ9",
      // "embedded_tool_link": "https://app.powerbi.com/view?r=eyJrIjoiZWFmOTBkOWEtNmY2NS00YTA2LWJhOTItZjIwZjgxNmIxMGI2IiwidCI6Ijc3MTg0ZDk5LTYwNjUtNDJiOS05NDUyLThiMmI3MDM4NTE5MyJ9",
      "feature_heading": "Using Promo Diagnostic Tool, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "KPI Trend Charts",
          "value_desc": [
            "Identify relations between KPIs using Trend charts"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Portfolio Planning",
          "value_desc": [
            "Optimize your portfolio plan for seasonal & competitive gains"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "ROI Benchmarking",
          "value_desc": [
            "Benchmark SKU/Brand and Promo Tactic based on ROI & Lift"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "True Volume Uplift",
          "value_desc": [
            "Measure your forward buying and internal cannibalization trends"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "Promo Performance",
          "description": "Trended view of KPI's to understand which time period sees highest promo investment , lifts & discount depths.",
          "image": require("../Images/Picture1.jpg")
        }, {
          "heading": "Volume Lift Decomposition",
          "description": "Identify through charts, which SKU/Brand/Flavour etc. sees higher impact of FB/IC components.",
          "image": require("../Images/61.png")
        }, {
          "heading": "Promo Benchmarking",
          "description": "Identify promo performance in terms of key KPIs (Investment/ROI/Uplift) through selection criterion.",
          "image": require("../Images/71.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our promo diagnostic tool to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Promo What-If Simulator",
          "description": "Create Different Weekly Promo Scenarios",
          "projectLink": "promo-what-if"
        }
      ]
    },
    {
      "segment": "Promotional Analytics",
      "tool_name": "Promo What-If Simulator",
      "value_prop": "Maximize ROI and the total profit through optimal promotional planning",
      "value_desc": "",
      "key_points": [
        "Promotion Response Curve to estimate the planned promotion impact",
        "Collaboration for creating promotional plans at SKU x Week Level",
        "Choose your best Promo Spends plan and with maximum ROI",
        "Easy to use Scenario planning and comparison"
      ],
      "demo_link": "vAki6serEVg",
      "tool_link": process.env.REACT_APP_WHATIF_LINK,
      "embedded_tool_link": process.env.REACT_APP_WHATIF_LINK,
      "feature_heading": "Using What-If Promo Analyzer, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Weekly Scenarios",
          "value_desc": [
            "Create weekly scenarios inline to consumer demand",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Multi-SKU Simulator",
          "value_desc": [
            "Run Scenarios across the entire portfolio for maximum optimization",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Growth KPIs",
          "value_desc": [
            "Identify your growth KPIs to meet varying market needs",
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Easy Export",
          "value_desc": [
            "Export your results at desired granularities",
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Deepdive by brands/categories/SKU to show forecasted sales with other KPI over a given time for the particular scenario",
          "image": require("../Images/11.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our What-If Promo Analyzer to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Promo Diagnostics Tool",
          "description": "Evaluate Promo Strategies and ROI",
          "projectLink": "post-event-promo-diagnosis-dashboards"
        },
      ]
    },
    {
      "segment": "Price Management",
      "tool_name": "Pricing Analytics & Purchase Structure",
      "value_prop": "Develop better price propositions and better understanding of the shopper",
      "value_desc": "",
      "key_points": [
        "Capturing Commercial Portfolio Opportunity gaps",
        "Pricing opportunities basis premiumization",
        "Medium & Long Term Market gains",
        "Know your Brand Compass"
      ],
      "demo_link": "Alnu8v8TIMQ", 
      // "tool_link": "https://app.powerbi.com/groups/48c36d35-73ce-439c-8434-0d30a1acdedc/reports/69fd6a99-4dff-41b3-aa4b-0b56dfaf8c91/ReportSection5bcc6752472f3d34f2cb?experience=power-bi",
      // "embedded_tool_link": "https://app.powerbi.com/groups/48c36d35-73ce-439c-8434-0d30a1acdedc/reports/69fd6a99-4dff-41b3-aa4b-0b56dfaf8c91/ReportSection5bcc6752472f3d34f2cb?experience=power-bi",
      "tool_link":process.env.REACT_APP_PRICING_ANALYTICS_AND_PURCHASE_STRUCTURE,
      "embedded_tool_link":process.env.REACT_APP_PRICING_ANALYTICS_AND_PURCHASE_STRUCTURE,
      "feature_heading": "Using Price Architecture & Premiumization, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Price Positioning",
          "value_desc": [
            "Evaluate your price positioning & premiumness",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Price Indexation",
          "value_desc": [
            "Comparison of PPI for share gain strategies",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Consumer Map",
          "value_desc": [
            "Develop consumer catcher plans with preference charts",
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Brand Positioning",
          "value_desc": [
            "Know your consumers true brand compass to improve positioning",
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "Price Pack Architecture",
          "description": "Comparison of price equations across pack ranges to evaluate performances of brands across channels.",
          "image": require("../Images/13.png")
        }, {
          "heading": "Price Premiumization",
          "description": "Identify Premiumness index and brand positioning in terms of highest, lowest and current price.",
          "image": require("../Images/14.png")
        },
        {
          "heading": "Price Bracket Analysis",
          "description": "Evaluates performance across different price brackets with respect to competitors(Manufacturer/Brand/SKU level).",
          "image": require("../Images/16.png")
        }, {
          "heading": "PURCHASE STRUCTURE",
          "description": "Identify product segments clusters which have high interaction with each other in purchase decision.",
          "image": require("../Images/18.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Price Architecture & Premiumization to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Price-Volume-Profit Simulator",
          "description": "Price Volume Profit Change simulator",
          "projectLink": "pvp"
        },
      ]
    },
    {
      "segment": "Price Management",
      "tool_name": "Price-Volume-Profit Simulator",
      "value_prop": "Maximize gains in Volume & Profit through our pricing simulators",
      "value_desc": "",
      "key_points": [
        "Long term price planning",
        "Price Elasticity and Cross elasticity for pricing opportunities",
        "User centric Strategic Price Planning Always-On tool",
        "Collaboration for creating plans at SKU x Week Level"
      ],
      "demo_link": "T2kVcsKG89A",
      "tool_link": process.env.REACT_APP_PVP_LINK,
      "embedded_tool_link": process.env.REACT_APP_PVP_LINK,
      "feature_heading": "Using Price-Volume-Profit Simulator, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Targeted Pricing",
          "value_desc": [
            "Estimate impact on sales through targeted pricing",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Comparative Scenarios",
          "value_desc": [
            "Create & compare scenarios at SKU level for precision gains",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Growth KPIs",
          "value_desc": [
            "Evaluate volume lift, gross elasticity, profit margins vs baselines",
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Easy Export",
          "value_desc": [
            "Export your results at desired granularities",
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Gives the impact on sales,profit,NSV and volume change in percentages and in absolute numbers at various filters.",
          "image": require("../Images/91.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Price Volume Profit Simulator to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [

        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Pricing Analytics & Purchase Structure",
          "description": "Get Actionable insights on Brand Tiering and stratification",
          "projectLink": "pricing-dashboard"
        },
      ]
    },
    {
      "segment": "Assortment Analytics",
      "tool_name": "Store RGM",
      "value_prop": "Assortment, Merchandising, Price, Promotion & Shelf - 360° Store Deepdive",
      "value_desc": "",
      "key_points": [
        "Store compliance tracker",
        "360° Store analytics",
        "Size of price estimation",
      ],
      "demo_link": "Y6WWfrptEDY",
      "tool_link": process.env.REACT_APP_AMPPS_LINK,
      "embedded_tool_link": process.env.REACT_APP_AMPPS_LINK,
      "feature_heading": "Using AMPPS, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Distribution",
          "value_desc": [
            "Availability analytics to find the distribution of the prioritized SKUs",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Price Effect",
          "value_desc": [
            "Pricing analytics based on the executed and planned price",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Merchandise Gain",
          "value_desc": [
            "Execution analytics with respect to market share",
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Updated",
          "value_desc": [
            "Automated weekly data refresh for latest results",
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Best Use Case Consultants",
          "testimomial_desc": "The PoS Segmentation use case is the one where DP has a lot of knowledge ad expertise. They helped us to engage the Colombian teams and we developed the use case in compressed timing. I would like to recognize the excellent work and the difference it has made in our sales",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Overall Store AMPPS score and output for a particular set of stores based upon the 5 pillers.",
          "image": require("../Images/20.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our AMPPS to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/MicrosoftTeams-image (4).png"),
          "name": "Predictive Order",
          "description": "AI-powered tool that generates HYPER PERSONALIZED 'Suggested Orders'",
          "projectLink": "predictive-order"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Availability On-Shelf",
          "description": "Daily Track Of Your SKUs Availability",
          "projectLink": "aos"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Asset Planogram Tool",
          "description": "Utilize Shelf Space To Maximize Returns",
          "projectLink": "asset-planogram-tool"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Activation Analytics",
          "description": "Analyse Platform Execution Performance",
          "projectLink": "activation-analytics"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Assortment And Mix",
          "description": "Priotize Right Set of SKUs For Any Store",
          "projectLink":  process.env.REACT_APP_MIX_MANAGEMENT,
          "direct_link":true
        }
      ]
    },
    {
      "segment": "Assortment Analytics",
      "tool_name": "Assortment Prioritization",
      "value_prop": "Create customized must- have portfolio at store level",
      "value_desc": "",
      "key_points": [
        "Shopper centric segmentation",
        "Aligned holistic SKU prioritization for optimal coverage",
        "Manufacturer SKU walk rate to gain net incremental profit",
        "Core SKU coexistence deepdive"
      ],
      "demo_link": "t5WmIM2y9RM",
      "tool_link": "https://app.powerbi.com/view?r=eyJrIjoiNmNmZDdjZjYtOTVjNi00MjFkLWEzMDctODlkNjA4NTdiMzdmIiwidCI6Ijc3MTg0ZDk5LTYwNjUtNDJiOS05NDUyLThiMmI3MDM4NTE5MyJ9",
      "embedded_tool_link": "https://app.powerbi.com/view?r=eyJrIjoiNmNmZDdjZjYtOTVjNi00MjFkLWEzMDctODlkNjA4NTdiMzdmIiwidCI6Ijc3MTg0ZDk5LTYwNjUtNDJiOS05NDUyLThiMmI3MDM4NTE5MyJ9",
      "feature_heading": "Using Promo Diagnostic Tool, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Revenue Impact",
          "value_desc": [
            "Estimate net incremental impact of the SKU's"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Store Cluster",
          "value_desc": [
            "Localized store cluster prioritization grid"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Outreach Increase",
          "value_desc": [
            "Distribution expansion for new SKU's"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Best Use Case Consultants",
          "testimomial_desc": "The PoS Segmentation use case is the one where DP has a lot of knowledge ad expertise. They helped us to engage the Colombian teams and we developed the use case in compressed timing. I would like to recognize the excellent work and the difference it has made in our sales",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Prioritized SKU list by using sales velocity and manufacturer walk rate to maximize sales coverage, incremental sales and profit.",
          "image": require("../Images/26.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our promo diagnostic tool to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Store RGM",
          "description": "Keep Track Of Store Execution Score",
          "projectLink": "ammps"
        },
        {
          "logoLink": require("../Images/MicrosoftTeams-image (4).png"),
          "name": "Predictive Order",
          "description": "AI-powered tool that generates HYPER PERSONALIZED 'Suggested Orders'",
          "projectLink": "predictive-order"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Availability On-Shelf",
          "description": "Daily Track Of Your SKUs Availability",
          "projectLink": "aos"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Asset Planogram Tool",
          "description": "Utilize Shelf Space To Maximize Returns",
          "projectLink": "asset-planogram-tool"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Activation Analytics",
          "description": "Analyse Platform Execution Performance",
          "projectLink": "activation-analytics"
        },
      ]
    },
    {
      "segment": "Assortment Analytics",
      "tool_name": "Availability On Shelf",
      "value_prop": "Track you On-Shelf products with an Active Alert System",
      "value_desc": "",
      "key_points": [
        "Replenishment planning",
        "Demand forecasting",
        "Active OOS monitoring",
        "Lost revenue estimation"
      ],
      "demo_link": "CXVOrArZaBI",
      "tool_link": process.env.REACT_APP_AOS_DASHBOARD,
      "embedded_tool_link": process.env.REACT_APP_AOS_DASHBOARD,
      "feature_heading": "Using Availability On Shelf, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Smart Planinng",
          "value_desc": [
            "Create replenishment plans with active tracking"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Collaborative",
          "value_desc": [
            "Plan and forecast collaboratively for On-Shelf strategies"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Portfolio Adherence",
          "value_desc": [
            "Track your store portfolio and shelf space daily"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Best Use Case Consultants",
          "testimomial_desc": "The PoS Segmentation use case is the one where DP has a lot of knowledge ad expertise. They helped us to engage the Colombian teams and we developed the use case in compressed timing. I would like to recognize the excellent work and the difference it has made in our sales",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "Sellout and Portfolio",
          "description": "Identify sales and lost sales due to low OSA Score at daily, weekly, monthly, quarterly basis and compare it with last year.Track the portfolio adherence level of stores as per guidelines.",
          "image": require("../Images/34.png")
        }, {
          "heading": "Retail Execution",
          "description": "Track shelf and exhibition space and evaluate performance metrics of SKUs like sales, growth for a particular store.",
          "image": require("../Images/35.png")
        }, {
          "heading": "Forecast",
          "description": "Evaluate the accuracy and variation between your forecast and customer forecast to optimize your inventory levels as per demand without losing OOS.",
          "image": require("../Images/36.png")
        }, {
          "heading": "Executive Dashboard",
          "description": "Compare each customer based on the replenishment KPIs like Fill-Rate, OT, OTIF, OSA, Instock & DOH for last week and identify customers who are behind the target.",
          "image": require("../Images/37.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Availability On Shelf to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Store RGM",
          "description": "Keep Track Of Store Execution Score",
          "projectLink": "ammps"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Assortment And Mix",
          "description": "Priotize Right Set of SKUs For Any Store",
          "projectLink":  process.env.REACT_APP_MIX_MANAGEMENT,
          "direct_link":true
        },
        {
          "logoLink": require("../Images/MicrosoftTeams-image (4).png"),
          "name": "Predictive Order",
          "description": "AI-powered tool that generates HYPER PERSONALIZED 'Suggested Orders'",
          "projectLink": "predictive-order"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Asset Planogram Tool",
          "description": "Utilize Shelf Space To Maximize Returns",
          "projectLink": "asset-planogram-tool"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Activation Analytics",
          "description": "Analyse Platform Execution Performance",
          "projectLink": "activation-analytics"
        },
      ]
    },
    {
      "segment": "Assortment Analytics",
      "tool_name": "Asset Planogram Tool",
      "value_prop": "Create product placement scenarios within your assets per store",
      "value_desc": "",
      "key_points": [
        "SKU Placement Scenario",
        "Increase sales velocity",
      ],
      "demo_link": "OyPSiyqzNSI",
      "tool_link": process.env.REACT_APP_PLANOGRAM,
      "embedded_tool_link": process.env.REACT_APP_PLANOGRAM,
      "feature_heading": "Using Promo Diagnostic Tool, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Layout Planning",
          "value_desc": [
            "Plan store layout based on must sell & promoted SKUs"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Optimize shelf space",
          "value_desc": [
            "Limit SKU space for maximum shelf gains"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Updated",
          "value_desc": [
            "Automated weekly data refresh for latest results"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Best Use Case Consultants",
          "testimomial_desc": "The PoS Segmentation use case is the one where DP has a lot of knowledge ad expertise. They helped us to engage the Colombian teams and we developed the use case in compressed timing. I would like to recognize the excellent work and the difference it has made in our sales",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "It helps to have a good visibility, appearance and presence of store . It contains all vital information in a summary wise format through which we can peep into the details of the designed template and compare or edit or download the design.",
          "image": require("../Images/40.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our promo diagnostic tool to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Store RGM",
          "description": "Keep Track Of Store Execution Score",
          "projectLink": "ammps"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Assortment And Mix",
          "description": "Priotize Right Set of SKUs For Any Store",
          "projectLink":  process.env.REACT_APP_MIX_MANAGEMENT,
          "direct_link":true
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Availability On-Shelf",
          "description": "Daily Track Of Your SKUs Availability",
          "projectLink": "aos"
        },
        {
          "logoLink": require("../Images/MicrosoftTeams-image (4).png"),
          "name": "Predictive Order",
          "description": "AI-powered tool that generates HYPER PERSONALIZED 'Suggested Orders'",
          "projectLink": "predictive-order"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Activation Analytics",
          "description": "Analyse Platform Execution Performance",
          "projectLink": "activation-analytics"
        }
      ]
    },
    {
      "segment": "Assortment Analytics",
      "tool_name": "Activation Analytics",
      "value_prop": "Amplify product sales with high impact visibility strategies",
      "value_desc": "",
      "key_points": [
        "Activation impact tracking",
        "Platform execution planning",
      ],
      "demo_link": "Li_ROfqX6d4",
      "tool_link": process.env.REACT_APP_ACTIVATION_ANALYTICS,
      "embedded_tool_link": null,
      "feature_heading": "Using Promo Diagnostic Tool, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Smart Planinng",
          "value_desc": [
            "Create replenishment plans with active tracking",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Collaborative",
          "value_desc": [
            "Plan and forecast collaboratively for On-Shelf strategies",
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Portfolio Adherence",
          "value_desc": [
            "Track your store portfolio and shelf space daily",
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Best Use Case Consultants",
          "testimomial_desc": "The PoS Segmentation use case is the one where DP has a lot of knowledge ad expertise. They helped us to engage the Colombian teams and we developed the use case in compressed timing. I would like to recognize the excellent work and the difference it has made in our sales",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Benchmark different type of shopper activities executed using profit ROI and uplift and quadrant analysis for investment optimization.",
          "image": require("../Images/28.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our promo diagnostic tool to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Store RGM",
          "description": "Keep Track Of Store Execution Score",
          "projectLink": "ammps"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Assortment And Mix",
          "description": "Priotize Right Set of SKUs For Any Store",
          "projectLink":  process.env.REACT_APP_MIX_MANAGEMENT,
          "direct_link":true
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Availability On-Shelf",
          "description": "Daily Track Of Your SKUs Availability",
          "projectLink": "aos"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Asset Planogram Tool",
          "description": "Utilize Shelf Space To Maximize Returns",
          "projectLink": "asset-planogram-tool"
        },
        {
          "logoLink": require("../Images/MicrosoftTeams-image (4).png"),
          "name": "Predictive Order",
          "description": "AI-powered tool that generates HYPER PERSONALIZED 'Suggested Orders'",
          "projectLink": "predictive-order"
        }
      ]
    },
    {
      "segment": "Assortment Analytics",
      "tool_name": "Predictive Order",
      "value_prop": "AI powered perdictive orders per store",
      "value_desc": "",
      "key_points": [
        "Inventory Management",
        "Reduced Out-Of Stock SKUs",
        "AI Powered and localized clustering"
      ],
      "demo_link": "",
      "tool_link": "#",
      "feature_heading": "Using Predictive Order, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Personalized",
          "value_desc": [
            "Improved Engagement and Personalization for Consumers"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Drive Sales",
          "value_desc": [
            "Increase revenue potential for each outlet"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Inventorized",
          "value_desc": [
            "Efficiently manage retail and manufacturer inventory"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Best Use Case Consultants",
          "testimomial_desc": "The PoS Segmentation use case is the one where DP has a lot of knowledge ad expertise. They helped us to engage the Colombian teams and we developed the use case in compressed timing. I would like to recognize the excellent work and the difference it has made in our sales",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Predictive Assortment Order to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Store RGM",
          "description": "Keep Track Of Store Execution Score",
          "projectLink": "ammps"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Assortment And Mix",
          "description": "Priotize Right Set of SKUs For Any Store",
          "projectLink":  process.env.REACT_APP_MIX_MANAGEMENT,
          "direct_link":true
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Availability On-Shelf",
          "description": "Daily Track Of Your SKUs Availability",
          "projectLink": "aos"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Asset Planogram Tool",
          "description": "Utilize Shelf Space To Maximize Returns",
          "projectLink": "asset-planogram-tool"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Activation Analytics",
          "description": "Analyse Platform Execution Performance",
          "projectLink": "activation-analytics"
        }
      ]
    },
    {
      "segment": "Trade Management",
      "tool_name": "FABLE",
      "value_prop": "Empower your sales team with hyper-personalized trade stories",
      "value_desc": "",
      "key_points": [
        "Store specific execution",
        "Portfolio opportunity",
        "Shopper Distinction",
        "Brand Strategy"
      ],
      "demo_link": "",
      "tool_link": "#",
      "feature_heading": "Using FABLE, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Targeted Audience",
          "value_desc": [
            "Capture the opportunity gap at granular level"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Attribute Match",
          "value_desc": [
            "Highlight the right attributes vs the competition"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Catcher Plans",
          "value_desc": [
            "Create shopper distinctions and catcher plans gaining margins"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Fable - Selling Hyperpersonalized to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Asset Placement Simulator",
          "description": "Identify the ideal placement of the asset",
          "projectLink": "Asset-placement-simulator"
        }
      ]
    },
    {
      "segment": "Trade Management",
      "tool_name": "Asset Placement Simulator",
      "value_prop": "Capture maximum consumer traffic every occasion with best placement scenarios",
      "value_desc": "",
      "key_points": [
        "Maximize consumer traffic",
        "Increase sales velocity",
        "User centric scenario planner"
      ],
      "demo_link": "kgPcLdnIBRM",
      "tool_link": process.env.REACT_APP_PLACEMENT,
      "embedded_tool_link": process.env.REACT_APP_PLACEMENT,
      "feature_heading": "Using Asset Placement Simulator, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Asset Returns",
          "value_desc": [
            "Maximize returns by knowing where & how to place assets"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Asset KPIs",
          "value_desc": [
            "Customized weightage to KPIs to strategical place your assets"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "User Scenario",
          "value_desc": [
            "Create user scenarios on the web on the go"
          ]
        },
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Identify count of placement across various expansion levers and evaluate impact in terms of profit, ROI, Share, Density etc.",
          "image": require("../Images/30.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Asset Placement Simulator to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "FABLE",
          "description": "Identify the Store level Opportunity",
          "projectLink": "fable"
        },
      ]
    },
    {
      "segment": "Integrated Tools",
      "tool_name": "Integrated Business Planner",
      "value_prop": "Ease your planning for business goals with our best in class integrated planner",
      "value_desc": "",
      "key_points": [
        "Real-time scenario",
        "Growth drivers/draggers",
        "Gap closure initiatives",
        "AI Powered"
      ],
      "demo_link": "4Bo14bg4cx4",
      "tool_link": process.env.REACT_APP_COMMERCIAL_PLANNER,
      "embedded_tool_link": process.env.REACT_APP_COMMERCIAL_PLANNER,
      "feature_heading": "Using Integrated Business Planner, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Reduce planning time",
          "value_desc": [
            "Run scenarios across teams and organization"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Drivers/Draggers",
          "value_desc": [
            "Know building blocks of your business and measure their impact"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Modelling",
          "value_desc": [
            "Advanced machine learning algorithms and planning capabilities."
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Integrated",
          "value_desc": [
            "Take decisions across the entire value chain from supply to finances"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Profit and loss compares the gross sale, trade spending, percentage spend, net sales, COGS, gross profit, percentage profit of the business.",
          "image": require("../Images/22.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Integrated Commercial Planner/HILO to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Beagle",
          "description": "Get Frequent Alerts For Your Analysis",
          "projectLink": "simulator-chatbot"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Target Group Builder",
          "description": "Target Right Brands In Right Stores",
          "projectLink": "target-group-builder"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Demand Planning",
          "description": "Forecast Demands Accurately According To External Factors",
          "projectLink": "demand-planning"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Shopper Marketing",
          "description": "Know Your Audience For Your Portfolio",
          "projectLink": "pos-initiative"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Growth Decomposition Tool",
          "description": "Understand Growth Levers ",
          "projectLink": "market-share-tool"
        }
      ]
    },
    {
      "segment": "Integrated Tools",
      "tool_name": "Target Group Builder",
      "value_prop": "Manage new product campaigns with speed and dynamicity for each store group",
      "value_desc": "",
      "key_points": [
        "Brand positioning",
        "Product Segmentation",
        "Governance Standardization",
      ],
      "demo_link": "9ZgScZjYmGE",
      "tool_link": process.env.REACT_APP_TARGET_BUILDER,
      "embedded_tool_link": process.env.REACT_APP_TARGET_BUILDER,
      "feature_heading": "Using Target Group Builder, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Targeted Action",
          "value_desc": [
            "Group using store, product & shopper attributes"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Plan vs Actual",
          "value_desc": [
            "Quick analysis of planned vs achieved target with historical data"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Time Periods",
          "value_desc": [
            "Plan, track and estimate revenue across time periods"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Updated",
          "value_desc": [
            "Automated weekly data refresh for latest results"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Track monthly performance like sales, store count, distribution and SKU range of the target group and benchmark with the target defined.",
          "image": require("../Images/39.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Target Group Builder to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Integrated Business Planner",
          "description": "Synchronized Planning With All Business Unit Involved",
          "projectLink": "integrated-commercial-planner"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Beagle",
          "description": "Get Frequent Alerts For Your Analysis",
          "projectLink": "simulator-chatbot"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Demand Planning",
          "description": "Forecast Demands Accurately According To External Factors",
          "projectLink": "demand-planning"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Shopper Marketing",
          "description": "Know Your Audience For Your Portfolio",
          "projectLink": "pos-initiative"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Growth Decomposition Tool",
          "description": "Understand Growth Levers ",
          "projectLink": "market-share-tool"
        },
      ]
    },
    {
      "segment": "Integrated Tools",
      "tool_name": "Demand Planning",
      "value_prop": "Create, Compare and Plan sales during uncertain times",
      "value_desc": "",
      "key_points": [
        "User centric scenario planner",
        "Cross functional",
        "Financial Planning",
        "Integrated platform"
      ],
      "demo_link": "T6BhbpjnxN8",
      "tool_link": process.env.REACT_APP_DEMAND_PLANNING,
      "embedded_tool_link":process.env.REACT_APP_DEMAND_PLANNING,
      "feature_heading": "Using Demand Planning tool, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Reduce planning time",
          "value_desc": [
            "Run scenarios across teams and organization"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Data driven",
          "value_desc": [
            "Highlight risks & opportunities by comparing data driven forecasts"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Modelling",
          "value_desc": [
            "Demand forecasting based on external factors and activations"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Integrated",
          "value_desc": [
            "Take decisions across the entire value chain from supply to finances"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Compares the relative performance, trends and overall drivers to help the business in high grow scenario and organic growth scenario.",
          "image": require("../Images/DemandPlanningUseCase.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Demand Planning tool to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [

        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Integrated Business Planner",
          "description": "Synchronized Planning With All Business Unit Involved",
          "projectLink": "integrated-commercial-planner"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Target Group Builder",
          "description": "Target Right Brands In Right Stores",
          "projectLink": "target-group-builder"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Shopper Marketing",
          "description": "Know Your Audience For Your Portfolio",
          "projectLink": "pos-initiative"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Growth Decomposition Tool",
          "description": "Understand Growth Levers ",
          "projectLink": "market-share-tool"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Beagle",
          "description": "Get Frequent Alerts For Your Analysis",
          "projectLink": "simulator-chatbot"
        }
      ]
    },
    {
      "segment": "Integrated Tools",
      "tool_name": "Shopper Marketing",
      "value_prop": "Increase product sales with shopper & retailer centric customer segmentation",
      "value_desc": "",
      "key_points": [
        "Consumer Segmentation",
        "Centralized Execution",
        "Media & In-store Study",
        "Clustering analysis and refinement"
      ],
      "demo_link": "Zgm-U7i4azk",
      "tool_link": process.env.REACT_APP_SHOPPER_MARKETING,
      "embedded_tool_link": process.env.REACT_APP_SHOPPER_MARKETING,
      "feature_heading": "Using Shopper Marketing, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Targeted Action",
          "value_desc": [
            "Customized product portfolio for the selected target audience"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Central Execution",
          "value_desc": [
            "Single interface, centralized planning, execution and tracking"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Dynamic Segmentation",
          "value_desc": [
            "Consumer/shopper segmentation with different touchpoints"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Extensize Data",
          "value_desc": [
            "Integration of multiple consumer centric data across segments"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "Evaluate KPIs performance at SEC, Family Size, Children, Retailer Level and compare with the target defined.",
          "image": require("../Images/32.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our E2E Shopper/ POS Initiative to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Integrated Business Planner",
          "description": "Synchronized Planning With All Business Unit Involved",
          "projectLink": "integrated-commercial-planner"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Target Group Builder",
          "description": "Target Right Brands In Right Stores",
          "projectLink": "target-group-builder"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Demand Planning",
          "description": "Forecast Demands Accurately According To External Factors",
          "projectLink": "demand-planning"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Growth Decomposition Tool",
          "description": "Understand Growth Levers ",
          "projectLink": "market-share-tool"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Beagle",
          "description": "Get Frequent Alerts For Your Analysis",
          "projectLink": "simulator-chatbot"
        }
      ]
    },
    {
      "segment": "Integrated Tools",
      "tool_name": "Growth Decomposition Tool",
      "value_prop": "Know your market drivers to grow in times of uncertainty",
      "value_desc": "",
      "key_points": [
        "Root cause analysis",
        "Driver inter-dependency",
        "Visualize your business"
      ],
      "demo_link": "xcrfL_jX92o",
      "tool_link": process.env.REACT_APP_GROWTH_DECOMPOSITION,
       "embedded_tool_link": process.env.REACT_APP_GROWTH_DECOMPOSITION,
       "feature_heading": "Using Growth Decomposition Tool, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Levers Deepdive",
          "value_desc": [
            "Deepdive into the business portfolio and operational levers"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Precise",
          "value_desc": [
            "Know the markets & products driving growth and share change"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Visualize",
          "value_desc": [
            "Innovative visuals with advanced algorithm to demystify growth"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Granularized",
          "value_desc": [
            "Drill down at most granular level and track your KPIs"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [
        {
          "heading": "",
          "description": "The Growth Explorer tool helps to find out the Share Gain / Loss and Revenue Growth / Degrowth at a granular level.",
          "image": require("../Images/24.png")
        }
      ],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Market Share Tool (Growth decomp Tool) to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Integrated Business Planner",
          "description": "Synchronized Planning With All Business Unit Involved",
          "projectLink": "integrated-commercial-planner"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Target Group Builder",
          "description": "Target Right Brands In Right Stores",
          "projectLink": "target-group-builder"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Demand Planning",
          "description": "Forecast Demands Accurately According To External Factors",
          "projectLink": "demand-planning"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Shopper Marketing",
          "description": "Know Your Audience For Your Portfolio",
          "projectLink": "pos-initiative"
        },
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Beagle",
          "description": "Get Frequent Alerts For Your Analysis",
          "projectLink": "simulator-chatbot"
        }
      ]
    },
    {
      "segment": "Integrated Tools",
      "tool_name": "Beagle",
      "value_prop": "More intuitive than a chat bot, gives insights and is always updated",
      "value_desc": "",
      "key_points": [
        "Real time actionable insights",
        "Customizable across teams",
        "Personalized Alerts",
        "Single click integration"
      ],
      "demo_link": "M31uzODsuOo",
      "tool_link": process.env.REACT_APP_BEAGLE,
      "embedded_tool_link": null,
      "feature_heading": "Using Beagle, you can look forward to",
      "features": [
        {
          "image": require("../Images/Frame.png"),
          "value": "Easy Deployment",
          "value_desc": [
            "Integrated data source and management"]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Nudges",
          "value_desc": [
            "Personalized daily report and alerts"
          ]
        },
        {
          "image": require("../Images/Frame.png"),
          "value": "Collaborative",
          "value_desc": [
            "Cross departmental collaboration and real time shareability"
          ]
        }, {
          "image": require("../Images/Frame.png"),
          "value": "Integratable",
          "value_desc": [
            "Accessible directly from MS Teams for on the go analysis"
          ]
        }
      ],
      "testimomials": [
        {
          "image": require("../Images/Kellogs.png"),
          "testimomial_header": "Kelloggs saw a +3% rise in profit",
          "testimomial_desc": "A product recommendation engine that not only suggests the product to be sold to a customer during a sales visit but also provides a personalized sales pitch to empower the sales rep to develop his relationship with the customer and achieve his targets at the same time.",
          "author_image": require("../Images/Kellogs.png"),
          "author": "",
          "designation": "Commercial Director"
        },
        {
          "image": require("../Images/Coke.png"),
          "testimomial_header": "Dynamic, Intellectual & Flexible",
          "testimomial_desc": "The work the team delivered has provided key insights for our planning process and informed opportunities and strategic priorities. The team is also great to work with - conducts analysis proficiently, meets deadlines, is flexible to changing requests and has a great attitude towards clients. I really had a positive experience working with Decision Point",
          "author_image": require("../Images/Coke.png"),
          "author": "",
          "designation": "Commercial Director"
        }
      ],
      "properties": [],
      "cta_heading": "Turn complex data into actionable insights",
      "cta_desc": "Use our Beagle to turn your insights into actions",
      "cta_section_image": require("../Images/growth 1.png"),
      "extra_tools": [
        {
          "logoLink": require("../Images/Group 210.png"),
          "name": "Integrated Business Planner",
          "description": "Synchronized Planning With All Business Unit Involved",
          "projectLink": "integrated-commercial-planner"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Target Group Builder",
          "description": "Target Right Brands In Right Stores",
          "projectLink": "target-group-builder"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Demand Planning",
          "description": "Forecast Demands Accurately According To External Factors",
          "projectLink": "demand-planning"
        },
        {
          "logoLink": require("../Images/Rocket.png"),
          "name": "Shopper Marketing",
          "description": "Know Your Audience For Your Portfolio",
          "projectLink": "pos-initiative"
        },
        {
          "logoLink": require("../Images/Geometry.png"),
          "name": "Growth Decomposition Tool",
          "description": "Understand Growth Levers ",
          "projectLink": "market-share-tool"
        },
      ]
    },{
      segment: "Promotional Analytics",
      tool_name: "Promotion Optimizer",
      value_prop:
        "Optimize promotion budget allocation across products & customers based on defined objectives and business constraints.",
      value_desc: "",
      key_points: [
        "Promotion Calendar Generation across Customers and Products.",
        "Objective based allocation of promotion investment e.g., Maximizing Profit/ ROI/ Volume.",
        "Promo response curve to optimize the promo tactics and timing of promotions.",
        "Easy to optimize and benchmark various scenarios based on defined constraints."
      ],
      demo_link: "",
      tool_link: process.env.REACT_APP_PROMO_OPTIMIZER,
      embedded_tool_link: process.env.REACT_APP_PROMO_OPTIMIZER,
      feature_heading: "Using promotion Optimizer, you can look forward to",
      features: [
        {
          image: require("../Images/Frame.png"),
          value: "Multi-Customer and Portfolio optimization",
          value_desc: [
            "Run optimization algorithms across customers and portfolio."
          ]
        },
        {
          image: require("../Images/Frame.png"),
          value: "Seamless integration APIs",
          value_desc: [
            "Integration APIs for TPM/ handheld device and tracking systems."
          ]
        },
        {
          image: require("../Images/Frame.png"),
          value: "Business Ready Promotion Calendar",
          value_desc: [
            "Optimized promo calendar generated for customers’ communication."
          ]
        },
        {
          image: require("../Images/Frame.png"),
          value: "Holistic Planning",
          value_desc: [
            "Allows input of business execution constraints like pre-aligned customer events, customer co-investment, promo mechanics, promo frequency, etc."
          ]
        }
      ],
      testimomials: [],
      properties: [],
      cta_heading:
        "Optimize promotion budget allocation across products & customers based on defined objectives and business constraints.",
      cta_desc:
        "Use Promotion Optimizer to generate Promo Calendar with optimal budget allocation across Customers and Products.",
      cta_section_image: require("../Images/growth 1.png"),
      extra_tools: [
        {
          logoLink: require("../Images/Group 210.png"),
          name: "Promo What-If Simulator",
          description: "Create Different Weekly Promo Scenarios",
          projectLink: "promo-what-if"
        }
      ]
    },
    {
      segment: 'Assortment Analytics',
      tool_name: 'Assortment and Mix',
      value_prop:'',
       value_desc: '',
      key_points: [
      ],
      demo_link: '',
      tool_link: process.env.REACT_APP_MIX_MANAGEMENT,
      embedded_tool_link: process.env.REACT_APP_MIX_MANAGEMENT,
      feature_heading: '',
      features: [
        // {
        //   image:
        //    "", value: '',
        //   value_desc: [
        //     '',
        //   ],
        // },
      ],
      testimomials: [],
      properties: [],
      cta_heading:
        '',
      cta_desc:
        '',
      cta_section_image: '',
      extra_tools: [
        // {
        //   logoLink:
        //     'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAXCAYAAAAV1F8QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgBvVXRDYIwFHxNHMARHIERGMER3ACdAJwAR9AJGAE3gA0YATeo98KZED+krTwuuZCQ8q7Xe32I/ID3/iiWgMAebP2EXCyAwgdwoMhZLKBHBY4UysQCKFzSRaeuZGU4zQPPGjyBPXgFX0EfO/eUUGgOPh3BGe7AO3gACzq6SDh6iQV2V3GXg0VG32L2XTcTS7pHSVOEYg3FFp3pGq5tko49Zpez7t0k44xCo99gIM8zLj/vXUQBnSAxnVhz/U2maRMs9NcECXZEsTxwqbov6egBrv+7YUYdnVRigU26DoUL83uk2VGkZXfaYemo3qX2gwZPsRAGAAAAAElFTkSuQmCC',
        //   name: 'Promo What-If Simulator',
        //   description: 'Create Different Weekly Promo Scenarios',
        //   projectLink: 'promo-what-if',
        // },
      ],
    },
    {
      segment: 'All Tools',
      tool_name: 'Command Center',
      value_prop:'',
       value_desc: '',
      key_points: [
      ],
      demo_link: '',
      tool_link: process.env.REACT_APP_COMMAND_CENTER,
      embedded_tool_link: process.env.REACT_APP_COMMAND_CENTER,
      feature_heading: '',
      features: [
        // {
        //   image:
        //    "", value: '',
        //   value_desc: [
        //     '',
        //   ],
        // },
      ],
      testimomials: [],
      properties: [],
      cta_heading:
        '',
      cta_desc:
        '',
      cta_section_image: '',
      extra_tools: [
        // {
        //   logoLink:
        //     'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAXCAYAAAAV1F8QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgBvVXRDYIwFHxNHMARHIERGMER3ACdAJwAR9AJGAE3gA0YATeo98KZED+krTwuuZCQ8q7Xe32I/ID3/iiWgMAebP2EXCyAwgdwoMhZLKBHBY4UysQCKFzSRaeuZGU4zQPPGjyBPXgFX0EfO/eUUGgOPh3BGe7AO3gACzq6SDh6iQV2V3GXg0VG32L2XTcTS7pHSVOEYg3FFp3pGq5tko49Zpez7t0k44xCo99gIM8zLj/vXUQBnSAxnVhz/U2maRMs9NcECXZEsTxwqbov6egBrv+7YUYdnVRigU26DoUL83uk2VGkZXfaYemo3qX2gwZPsRAGAAAAAElFTkSuQmCC',
        //   name: 'Promo What-If Simulator',
        //   description: 'Create Different Weekly Promo Scenarios',
        //   projectLink: 'promo-what-if',
        // },
      ],
    }
  ],
  MY_SCENARIOS_DATA: [
    {
      "scenario": "All",
      "data": {
        "columns": [
          "Scenario",
          "Total Lift (Volume)",
          "Forward Buying (Volume)",
          "Internal Cannibalization (Volume)",
          "True Lift (Volume)",
          "Baseline Volume",
          null
        ],
        "values": [
          [
            "Portfolio Innovation Scenario",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 2",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 3",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 4",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 5",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ]
        ],
        "impact": [
          "4.3",
          "2.5",
          "0.9",
          "0.5",
          "2.5"
        ]
      }
    },
    {
      "scenario": "Demand Planning",
      "data": {
        "columns": [
          "Scenario",
          "Total Lift (Volume)",
          "Forward Buying (Volume)",
          "Internal Cannibalization (Volume)",
          "True Lift (Volume)",
          "Baseline Volume",
          null
        ],
        "values": [
          [
            "Portfolio Innovation Scenario",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 2",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 3",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 4",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 5",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ]
        ],
        "impact": [
          "4.3",
          "2.5",
          "0.9",
          "0.5",
          "2.5"
        ]
      }
    },
    {
      "scenario": "What If Promo",
      "data": {
        "columns": [
          "Scenario",
          "Total Lift (Volume)",
          "Forward Buying (Volume)",
          "Internal Cannibalization (Volume)",
          "True Lift (Volume)",
          "Baseline Volume",
          null
        ],
        "values": [
          [
            "Portfolio Innovation Scenario",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 2",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 3",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 4",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 5",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ]
        ],
        "impact": [
          "4.3",
          "2.5",
          "0.9",
          "0.5",
          "2.5"
        ]
      }
    },
    {
      "scenario": "Market Share",
      "data": {
        "columns": [
          "Scenario",
          "Total Lift (Volume)",
          "Forward Buying (Volume)",
          "Internal Cannibalization (Volume)",
          "True Lift (Volume)",
          "Baseline Volume",
          null
        ],
        "values": [
          [
            "Portfolio Innovation Scenario",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 2",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 3",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 4",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 5",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ]
        ],
        "impact": [
          "4.3",
          "2.5",
          "0.9",
          "0.5",
          "2.5"
        ]
      }
    },
    {
      "scenario": "Target Builder",
      "data": {
        "columns": [
          "Scenario",
          "Total Lift (Volume)",
          "Forward Buying (Volume)",
          "Internal Cannibalization (Volume)",
          "True Lift (Volume)",
          "Baseline Volume",
          null
        ],
        "values": [
          [
            "Portfolio Innovation Scenario",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 2",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 3",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 4",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ],
          [
            "Scenario 5",
            34,
            "23.5M",
            "65%",
            "62%",
            "62%",
            "#"
          ]
        ],
        "impact": [
          "4.3",
          "2.5",
          "0.9",
          "0.5",
          "2.5"
        ]
      }
    }
  ]
}