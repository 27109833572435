import axios from "axios";
export const postData = async (data) => {
  const baseUrl = process.env.REACT_APP_HUBBLE_BACKEND_URL + "saveToken";
  // let options = {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json;charset=utf-8",
  //   },
  //   body: JSON.stringify(data),
  //   // credentials: "include", // This allows sending cookies in cross-origin requests
  // };
  return axios.post(baseUrl, data);
};
export const postData2 = async (data) => {
  const baseUrl = process.env.REACT_APP_HUBBLE_BACKEND_URL_OLD + "/saveToken";
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(data),
  };
  return fetch(baseUrl, options);
};
export const callApi = async (data) => {
  const baseUrl = process.env.REACT_APP_HUBBLE_BACKEND_URL + "usersDetails";
  // axios.defaults.headers.common["Content-Type"] = "application/json";
  // axios.defaults.headers.common["Accept"] = "application/json";

  const res = await axios.get(baseUrl, {
    headers: {
      Authorization: `${data}` || 0,
    },
  });
  return res;
};

export const getUserIp = async () => {
  const baseUrl = "https://geolocation-db.com/json/";
  let options = {
    method: "GET",
  };
  let response = await fetch(baseUrl);
  let res = await response.json();
  console.log("res=======>", res);
  return res;
};
