import React, { useEffect, useState } from "react";
import "./Navigation.css";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import GridViewIcon from "@mui/icons-material/GridView";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import avatar from "../../Images/avatar.jpg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Collapse,
  ListItem,
  ListItemIcon,
  List,
  Icon,
  Typography,
  OutlinedInput,
  Divider,
} from "@material-ui/core";
import { IMAGECONFIG } from "../../config/image-config";
import SearchIcon from "@mui/icons-material/Search";
import TOOLS_LIST from "./Navigation-config";
import { setToolData } from "../../redux/actions/tool-action";
import {
  getPermission,
  getComingFrom,
  setComingFrom,
  setPermission,
} from "../../utils/services";
import {
  getCookie,
  deleteEmptyHubbleAccessTokenCookie,
} from "../../utils/getCookie";
import { useNavigate } from "react-router-dom";
import { FormControl, InputAdornment } from "@mui/material";
import { connect } from "react-redux";
import { postData, callApi } from "../../utils/dataServices";
import { keys } from "@material-ui/core/styles/createBreakpoints";
import HelpIcon from "@mui/icons-material/Help";
import Help from "@mui/icons-material/Help";
import Help_list from "./Help-config";
import { fontSize } from "@mui/system";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Icon as IconSemantic } from "semantic-ui-react";

const Navigation = ({
  tool_data,
  iframeLink,
  setIframeModalOpen,
  iframeModalOpen,
  setIsLoading,
  setIframeLink,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [pinMenu, setPinMenu] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const [help, setHelp] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [toolsList, setToolsList] = useState(TOOLS_LIST);
  const { trackPageView } = useMatomo();
  const [anchorEl, setAnchorEl] = useState(null);

  React.useEffect(() => {
    trackPageView({ href: window.location.href });
  }, [window.location.href]);

  const pinMenuBar = () => {
    console.log(pinMenu);
    setPinMenu(!pinMenu);
  };

  const toggleNavbar = () => {
    setOpen(!open);
    if (openIndex !== -1) {
      setOpenIndex(-1);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let comingFrom = getComingFrom();
    if (comingFrom === "" || !comingFrom) {
      setComingFrom("navigation");
      console.log("testing")
      console.log(getCookie("hubble_access_token"))
      console.log(getCookie("hubble_access_token"))
      console.log(getCookie("hubble_access_token"))
      console.log(getCookie("hubble_access_token"))
      console.log(getCookie("hubble_access_token"))

      callApi(getCookie("hubble_access_token"))
        .then((res) => {
          console.log("res", res);
          if (res['data']['code'] !== 200) {
            document.cookie =
              "hubble_access_token=;path=/;domain=.decisionpoint.in;";
            navigate("/");
          }
        })
        .catch((err) => {
          console.log("error message", err.message);
          document.cookie =
            "hubble_access_token=;path=/;domain=.decisionpoint.in;";
          navigate("/");
        });
      // console.log("getComingFrom", getComingFrom(), "cookie", getCookie("hubble_access_token"))
      // if (getCookie("hubble_access_token") && !getCookie("hubble_access_token_via_linkedin")) {
      //   navigate("/saml-login");
      // } else if (getCookie("hubble_access_token_via_linkedin") && !getCookie("hubble_access_token")) {
      //   navigate("/linkedin_login");
      // } else {
      //   logout();
      // }
    }
    console.log("set");
    console.log(comingFrom);
  }, []);

  let activeClass = {
    color: "#3DD5C8",
    fontWeight: 400,
  };

  const toggleOpenIndex = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index);
    } else {
      setOpenIndex(-1);
    }
  };

  const handleChange = (e, term) => {
    let value = term;
    setSearchTerm(value);
    let filteredData = TOOLS_LIST.filter((li) =>
      li.items
        ? li["items"].find(
            (it) =>
              it.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
              li.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : li.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredData.length > 0) {
      setToolsList(filteredData);
    } else if (searchTerm !== 0 && filteredData.length == 0) {
      setToolsList([]);
    } else {
      setToolsList(TOOLS_LIST);
    }
    console.log("searchTerm========>", searchTerm);
    console.log("filteredData========>", filteredData);
  };
  useEffect(() => {
    let filteredData = TOOLS_LIST.filter((li) =>
      li.items
        ? li.items.find(
            (it) =>
              it.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
              li.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : li.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredData.length > 0) {
      setToolsList(filteredData);
    } else if (searchTerm !== 0 && filteredData.length == 0) {
      setToolsList([]);
    } else {
      setToolsList(TOOLS_LIST);
    }
  }, [searchTerm]);
  const logout = () => {
    document.cookie =
      `hubble_access_token=;domain=.decisionpoint.in` + ";path=/";
    document.cookie = `hubble_first_name=;domain=.decisionpoint.in` + ";path=/";
    document.cookie = `hubble_expiry=;domain=.decisionpoint.in` + ";path=/";
    document.cookie = `hubble_username=;domain=.decisionpoint.in` + ";path=/";
    localStorage.setItem("hubble_username", "");
    localStorage.setItem("hubble_accessToken", "");
    localStorage.setItem("hubble_expiry", "");
    localStorage.setItem("hubble_first_name", "");
    navigate("/");
  };

  return (
    <div className="main-content">
      <div
        className="sidebar"
        style={{ width: open || pinMenu ? "20vw" : "4.5vw", minWidth: "50px" }}
        onMouseEnter={toggleNavbar}
        onMouseLeave={toggleNavbar}
      >
        <div
          className="top-section"
          style={
            open || pinMenu ? { paddingLeft: "5px" } : { marginLeft: "-2px" }
          }
        >
          <div className="logo">
            <img
              onClick={() => navigate("/navigation/my-favourites")}
              src={
                open || pinMenu
                  ? IMAGECONFIG.HUBBLELOGO
                  : IMAGECONFIG.LOGOWITHOUTTEXT
              }
              style={{ maxWidth: "150px", height: "40px" }}
            />

            <div
              style={{ color: "white" }}
              onClick={pinMenuBar}
              className="pinBar"
            >
              <img
                onClick={pinMenuBar}
                src={pinMenu ? IMAGECONFIG.PIN : IMAGECONFIG.UNPIN}
                style={{ maxWidth: "32px" }}
              />
            </div>
          </div>
        </div>
        <div
          className="list"
          style={{ marginTop: open || pinMenu ? "0px" : "50px" }}
        >
          {open || pinMenu ? (
            <FormControl
              className="search-field"
              sx={{
                m: 1,
                width: "95%",
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: "rgba(237, 241, 244)",
                    borderRadius: "15px",
                  },
                },
              }}
              variant="outlined"
            >
              <OutlinedInput
                className="nav"
                id="outlined-adornment-weight"
                value={searchTerm}
                onChange={(e) => handleChange(e, e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "white" }} />
                  </InputAdornment>
                }
                variant="standard"
                color="warning"
                aria-describedby="outlined-weight-helper-text"
              />{" "}
            </FormControl>
          ) : (
            <SearchIcon
              fontSize="large"
              className="navlink-icon"
              color="#FFFFFF"
              style={{ color: "white", marginBottom: "10px" }}
            />
          )}
          <List>
            {toolsList.map((tool, i) => {
              return tool.items ? (
                <div key={i}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#FFFFFF",
                      opacity: 0.6,
                      display: open || pinMenu ? "block" : "none",
                      fontFamily: "Montserrat",
                      marginBottom: tool.header ? "20px" : "",
                    }}
                  >
                    {tool.header ? tool.header : ""}
                  </Typography>
                  <ListItem
                    className="list-item"
                    onClick={() => toggleOpenIndex(i)}
                  >
                    <div className="item-text">
                      <Icon
                        className="link-logo"
                        edge="start"
                        sx={{
                          ".active &": {
                            color: "#3DD5C8",
                            marginRight: "10px",
                            fontSize: "1.25em",
                          },
                        }}
                      >
                        {tool.icon}
                      </Icon>
                      <div
                        className="name"
                        style={{ display: open || pinMenu ? "block" : "none" }}
                      >
                        {tool.title}
                      </div>
                    </div>
                    {openIndex === i ? (
                      <ExpandLessIcon
                        style={{ display: open || pinMenu ? "block" : "none" }}
                      />
                    ) : (
                      <ExpandMoreOutlinedIcon
                        style={{ display: open || pinMenu ? "block" : "none" }}
                      />
                    )}
                  </ListItem>
                  <Collapse
                    className="collapse-list"
                    in={i === openIndex}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {tool.items.map((item, k) => {
                        return item.direct_to_tool ? (
                          <div
                            className="name"
                            style={{
                              display: open || pinMenu ? "block" : "none",
                              fontSize: "13px",
                              cursor: "pointer",
                              marginBottom: "15px",
                            }}
                            onClick={() => window.open(item.to)}
                          >
                            {item.to.length > 0 ? item.title : ""}
                          </div>
                        ) : (
                          <NavLink
                            key={k}
                            style={({ isActive }) =>
                              isActive ? activeClass : undefined
                            }
                            onClick={() => {
                              if (item.to === "/assortment-and-mix") {
                                setIsLoading(true);
                                setIframeLink(
                                  process.env.REACT_APP_MIX_MANAGEMENT
                                );
                                setTimeout(() => {
                                  setIframeModalOpen(true);
                                }, );
                              } else if (item.to === "/command-center") {
                                setIsLoading(true);
                                setIframeLink(
                                  process.env.REACT_APP_COMMAND_CENTER
                                );
                                setTimeout(() => {
                                  setIframeModalOpen(true);
                                }, 100);
                              }
                              return;
                            }}
                            to={`/navigation/tools${item.to}`}
                          >
                            <div
                              className="name"
                              style={{
                                display: open || pinMenu ? "block" : "none",
                                fontSize: "13px",
                              }}
                            >
                              {item.title}
                            </div>
                          </NavLink>
                        );
                      })}
                    </List>
                  </Collapse>
                  {tool.divider && <Divider />}
                </div>
              ) : (
                <div>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#FFFFFF",
                      opacity: 0.6,
                      display: open || pinMenu ? "block" : "none",
                      fontFamily: "Montserrat",
                      marginBottom: tool.header ? "20px" : "",
                    }}
                  >
                    {tool.header ? tool.header : ""}
                  </Typography>
                  <NavLink
                    to={`/navigation${tool.to}`}
                    style={({ isActive }) =>
                      isActive ? activeClass : undefined
                    }
                  >
                    {" "}
                    <Icon
                      className="link-logo"
                      edge="start"
                      sx={{
                        ".active &": {
                          color: "#3DD5C8",
                          marginRight: "10px",
                          fontSize: "1.25em",
                        },
                      }}
                    >
                      {tool.icon}
                    </Icon>
                    <div
                      className="name name-execute"
                      style={{ display: open || pinMenu ? "block" : "none" }}
                    >
                      {tool.title}
                    </div>
                  </NavLink>
                  {tool.divider && <Divider />}
                </div>
              );
            })}
            {toolsList.length == 0 && (
              <div style={{ color: "white" }}>No results found</div>
            )}
          </List>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{
              color: "#FFFFFF",
              opacity: 0.6,
              display: open || pinMenu ? "block" : "none",
              fontFamily: "Montserrat",
            }}
          >
            Quick Links
          </Typography>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => setHelp((prev) => (prev ? false : true))}
          >
            {Help_list.icon}
            <div
              style={{
                display: open || pinMenu ? "flex" : "none",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {Help_list.title}
              {help ? (
                <ExpandLessIcon
                  style={{ display: open || pinMenu ? "block" : "none" }}
                />
              ) : (
                <ExpandMoreOutlinedIcon
                  style={{ display: open || pinMenu ? "block" : "none" }}
                />
              )}
            </div>
          </a>
          <Collapse
            className="collapse-list"
            in={help}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <div
                style={{
                  display: open || pinMenu ? "block" : "none",
                  fontSize: "13px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {Help_list.items[0].title}
              </div>
            </List>
          </Collapse>
          <a style={{ cursor: "pointer" }} onClick={() => logout()}>
            <img src={IMAGECONFIG.LOGOUT} style={{ marginRight: "10px" }} />
            <div
              className="name"
              style={{ display: open || pinMenu ? "block" : "none" }}
            >
              Logout
            </div>
          </a>
        </div>
      </div>
      <div
        className="content"
        style={{ width: open || pinMenu ? "80vw" : "95.5vw" }}
      >
        <div
          className="static-navbar"
          style={{ width: open || pinMenu ? "80vw" : "95.5vw" }}
        >
          <div className="tool-data">
            {tool_data.segment && tool_data.tool_name ? (
              <div className="tool-first-row">
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#666666",
                    fontFamily: "Montserrat",
                  }}
                >
                  All Tools / {tool_data.segment}
                </Typography>
                {/* <Typography
                  variant="h5"
                  display="block"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    color: "#2D2A2B",
                    fontFamily: "Montserrat"
                  }}
                >
                  {tool_data.tool_name}
                </Typography> */}
              </div>
            ) : null}
          </div>
          <div className="user-details">
            {iframeModalOpen ? (
              <div
                className="action-bar"
                style={{
                  display: "flex",
                  // background: "#2D2A2B",
                  justifyContent: "flex-end",
                  padding: "5px",
                }}
              >
                <IconSemantic
                  onClick={() => window.open(`${iframeLink}`)}
                  size="large"
                  style={{ color: "black", cursor: "pointer" }}
                  name="window restore outline"
                />
                <IconSemantic
                  size="large"
                  style={{ color: "black", cursor: "pointer" }}
                  name="close"
                  onClick={() => {
                    if (tool_data.tool_name === "Assortment and Mix") {
                      navigate("/navigation/executive-dashboard");
                    } else if(tool_data.tool_name === "Command Center"){
                      navigate("/navigation/executive-dashboard");
                    }
                    else {
                      setIframeModalOpen(false);
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {/* <img
              style={{ cursor: "pointer" }}
              onClick={() => window.open("https://decisionpoint.ai/")}
              src={IMAGECONFIG.LOGO}
              alt="dp-logo"
            /> */}
            {/* <div style={{display:"flex",gap:"5px"}} onClick={handleClick}>
              <img
                className="user-avatar"
                style={{ cursor: "pointer" }}
                src={avatar}
                alt="dp-logo"
              />
              <div className="user"> {getCookie("hubble_first_name")}  {getCookie("hubble_last_name")}</div>
            </div> */}
            {/* <Menu 
              id="profile-menu"
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorEl={anchorEl}
            >
              <MenuItem style={{height:"40px",width:"150px",backgroundColor:"white"}} onClick={logout}>Logout</MenuItem>
            </Menu> */}
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  let { tool_data } = state.ToolReducerState;
  console.log(tool_data);
  return {
    tool_data: tool_data,
  };
};

export default connect(mapStateToProps, { setToolData })(Navigation);
